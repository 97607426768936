import { render, staticRenderFns } from "./NestedSelect.vue?vue&type=template&id=2889947e&scoped=true&"
import script from "./NestedSelect.vue?vue&type=script&lang=ts&"
export * from "./NestedSelect.vue?vue&type=script&lang=ts&"
import style0 from "./NestedSelect.vue?vue&type=style&index=0&id=2889947e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2889947e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCard,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VMenu,VProgressCircular,VTextField,VTextarea})
