
import Vue from "vue";
import ContentEditor from "@/components/form-components/ContentEditor.vue";

export default Vue.extend({
  components: { ContentEditor },

  name: "PresentedDocuments",

  props: {
    value: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },

  data: () => ({
    model: {} as any,
    localItems: [] as Array<any>,
    toCancel: false as boolean
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        this.model = this.value;
      }
    },
    model() {
      if (!this.model.type) {
        this.model.type = "documents";
      }
      this.$emit("input", this.model);
    }
  },

  methods: {
    async submit(): Promise<void> {
      if (this.toCancel) {
        await this.$router.push("/orders");
      }
    }
  }
});
