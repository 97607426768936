
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";
import ContentEditor from "@/components/form-components/ContentEditor.vue";

export default Vue.extend({
  components: { ContentEditor },

  name: "Examination",

  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    },
    errorMessages: {
      type: Object,
      default: () => ({}),
      required: false
    },
    value: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },

  data: () => ({
    rules,
    localItems: [] as Array<any>,
    toCancel: false as boolean,
    model: { type: "establishment" } as any,
    hiddenText: true as boolean
  }),

  watch: {
    items: {
      deep: true,
      immediate: true,
      handler() {
        this.localItems = this.items;
      }
    },
    value: {
      immediate: true,
      handler() {
        this.model = this.value;
      }
    },
    localItems: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit("update", this.localItems);
      }
    },
    model() {
      if (!this.model.type) {
        this.model.type = "establishment";
      }

      this.$emit("input", this.model);
    }
  },

  methods: {
    async submit(): Promise<void> {
      this.$emit("submit");

      if (this.toCancel) {
        await this.$router.push("/orders");
      }
    }
  }
});
