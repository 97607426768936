
import Vue from "vue";

export default Vue.extend({
  name: "NestedSelect",

  props: {
    value: {
      required: false,
      type: String
    },
    field: {
      required: false,
      type: String,
      default: "value"
    },
    label: {
      required: false,
      default: "",
      type: String
    },
    hideDetails: {
      required: false,
      default: false,
      type: Boolean
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean
    },
    outlined: {
      required: false,
      default: false,
      type: Boolean
    },
    dense: {
      required: false,
      default: false,
      type: Boolean
    },
    clearable: {
      required: false,
      default: false,
      type: Boolean
    },
    errorMessages: {
      required: false,
      default: () => [],
      type: Array
    },
    rules: {
      required: false,
      default: () => [],
      type: Array
    },
    searchFunction: {
      required: true,
      type: Function
    },
    textArea: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    loading: false as boolean,
    close: false as boolean,
    model: null as any,
    items: [] as Array<any>
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        this.model = this.value;
      }
    }
  },

  methods: {
    clearDate(): void {
      this.model = null;
      this.$emit("input", null);
    },
    async search(event: any): Promise<void> {
      this.close = false;
      try {
        if (event) {
          const { items } = await this.searchFunction(event, this.field);
          this.items = items;
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    selectChild(item: any) {
      this.$emit("input", item);
      this.close = true;
      this.items = [];
    }
  }
});
