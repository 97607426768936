import Vue from "vue";
export default Vue.extend({
  methods: {
    canEditField(key: string, field: string): boolean {
      if (this.model.order?.status?.alias === "issued") {
        return true;
      }

      if (!this.model.fields_access) {
        return false;
      }

      if (!this.model.fields_access[key]) {
        return false;
      }

      if (this.model.fields_access[key].length > 0) {
        return !this.model.fields_access[key].includes(field);
      }
      return false;
    },
    isSelectBeneficiary(): boolean {
      return !this.model.order.client_id;
    }
  }
});
