
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";
import CreatePayment from "@/modules/orders/components/dialogs/CreatePayment.vue";
import CreateGuarantee from "@/modules/orders/components/dialogs/CreateGuarantee.vue";

export default Vue.extend({
  name: "Payments",

  components: { CreateGuarantee, CreatePayment },

  props: {
    value: {
      type: Object,
      required: true
    },
    loadingPayments: {
      type: Boolean,
      default: false,
      required: false
    },
    showGuarantee: {
      type: Boolean,
      default: false,
      required: false
    },
    dataChanged: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data: () => ({
    rules,
    model: {} as any,
    confirmationFile: {} as any,
    isLoading: false as boolean,
    isLoadingGuarantee: false as boolean,
    newPaymentDialog: false as boolean,
    newGuaranteeDialog: false as boolean,
    isLoadingCreate: false as boolean,
    paymentsLog: [] as Array<any>,
    guaranteeLog: [] as Array<any>,
    services: [] as Array<any>,
    orderServices: [] as Array<any>,
    errorMessages: {} as any
  }),

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.model = this.value;

        if (this.value.details.services) {
          this.orderServices = this.value.details.services;
        }

        this.confirmationFile = this.model.order.confirmation_file || {};
      }
    },
    loadingPayments: {
      immediate: true,
      handler() {
        if (this.loadingPayments) {
          this.getData();
        }
      }
    },
    totalPayed: {
      immediate: true,
      handler() {
        this.$emit("setPaymentsPrice", this.totalPayed);
      }
    }
  },

  computed: {
    totalPayed() {
      return this.paymentsLog.reduce(
        (total: number, { sum }: { sum: number }) => {
          if (sum) {
            return total + Number(sum);
          }
          return total;
        },
        0
      );
    },
    totalGuaranteePaid() {
      return this.guaranteeLog.reduce(
        (total: number, { sum }: { sum: number }) => {
          if (sum) {
            return Math.floor((total + Number(sum)) * 100) / 100;
          }
          return Math.floor(total * 100) / 100;
        },
        0
      );
    },
    servicesVatTotal() {
      return this.orderServices?.reduce(
        (totalSumNoVat: number, { sum_with_vat }: { sum_with_vat: number }) => {
          if (sum_with_vat) {
            return totalSumNoVat + Number(sum_with_vat);
          }
          return totalSumNoVat;
        },
        0
      );
    },
    leftToPay() {
      return this.servicesVatTotal - Number(this.totalPayed);
    },
    guaranteeLeftToPay() {
      return Math.max(
        this.model.details.required_guaranty_sum -
          Number(this.totalGuaranteePaid),
        0
      );
    },
    canAddPayment(): boolean {
      return this.model.order.status.alias === "confirmed";
    }
  },

  async mounted() {
    await this.getData();
  },

  methods: {
    update() {
      this.$emit("update");
    },
    async removePayment(index: number, paymentId: number) {
      try {
        await this.$API.orders().deletePayment(paymentId);
        this.$emit("update");
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_removal")
        );
        await this.getOrderPayments();
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async removeGuarantee(index: number, paymentId: number) {
      try {
        await this.$API.guarantees().delete(paymentId);
        // this.guaranteeLog.splice(index, 1);
        this.$emit("update");
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_removal")
        );
        await this.getOrderGuarantees();
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async unlinkGuarantee(index: number, paymentId: number) {
      try {
        await this.$API.guarantees().unlinkFromOrder(paymentId);
        this.guaranteeLog.splice(index, 1);
        this.$emit("update");
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_removal")
        );
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async getData(): Promise<void> {
      this.isLoading = true;
      try {
        await this.getOrderGuarantees();
        await this.getOrderPayments();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.isLoading = false;
    },
    async getOrderPayments(): Promise<void> {
      this.isLoading = true;
      try {
        this.paymentsLog = await this.$API
          .payments()
          .getOrderPayments(Number(this.$route.params.id));
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.isLoading = false;
    },
    async getOrderGuarantees(): Promise<void> {
      this.isLoadingGuarantee = true;
      try {
        if (this.showGuarantee) {
          this.guaranteeLog = await this.$API
            .guarantees()
            .getOrderGuarantees(Number(this.$route.params.id));
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.isLoadingGuarantee = false;
    },
    downloadFile(file: any) {
      const element = document.createElement("a");
      element.setAttribute("href", file.url);
      element.setAttribute("target", "_blank");
      element.click();
    },
    toggleDialog(): void {
      this.newPaymentDialog = !this.newPaymentDialog;
    },
    toggleGuaranteeDialog(): void {
      this.newGuaranteeDialog = !this.newGuaranteeDialog;
    }
  }
});
