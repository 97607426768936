
import Vue from "vue";
import store from "@/store";
import API from "@/api/API";
import rules from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";
import stockConfig from "../../../config/stock-config.json";
import allowedFields from "../../../config/available-fields.json";
import DatePicker from "@/components/form-components/DatePicker.vue";
import CreateBeneficiary from "@/modules/orders/components/CreateBeneficiary.vue";
import ClientData from "@/modules/orders/components/ClientData.vue";
import Payments from "@/modules/orders/components/Payments.vue";
import Stock from "@/modules/orders/components/Stock.vue";
import PresentedDocuments from "../../PresentedDocuments.vue";
import Examination from "../../Examination.vue";
import ExpertConclusion from "../../ExpertConclusion.vue";
import Allocation from "@/modules/orders/components/Allocation.vue";
import CommonActions from "@/modules/orders/mixins/common_actions";
import Client from "@/modules/orders/mixins/client";
import Allocations from "@/modules/orders/mixins/allocations";
import Comments from "@/modules/orders/mixins/comments";
import Permissions from "@/modules/orders/mixins/permissions";
import Experts from "@/modules/orders/components/order_types/EXP/Experts.vue";
import Services from "@/modules/orders/components/Services.vue";
import FileUploader from "@/components/form-components/FileUploader.vue";

export default Vue.extend({
  name: "EvalCreateOrder",

  components: {
    Services,
    Experts,
    Allocation,
    Payments,
    DatePicker,
    CreateBeneficiary,
    ClientData,
    Stock,
    PresentedDocuments,
    Examination,
    ExpertConclusion,
    FileUploader
  },

  mixins: [CommonActions, Client, Allocations, Comments, Permissions],

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    currentStatusAlias: {
      type: String,
      required: true,
      default: ""
    }
  },

  directives: { mask },

  data: () => ({
    rules: rules as any,
    stockConfig: stockConfig as any,
    allowedFields: allowedFields as any,
    selectedField: "" as string,
    loading: false as boolean,
    loadingPayments: false as boolean,
    loadingCarnetIssused: false as boolean,
    selectedClient: null as null | SelectComponentInterface,
    selectedCard: null as null | SelectComponentInterface,
    model: {
      client_representatives: [] as Array<any>,
      documents: [] as Array<any>,
      order: {
        subdivision_id: 1,
        branch_id: 1,
        source: "operator",
        client_delegate: "",
        document_type: "new",
        client: { type: "" } as any,
        document_valid_from: new Date().toISOString().substr(0, 10),
        power_of_attorney: "beneficiary" as string
      } as any,
      details: {
        goods: [] as Array<any>,
        services: [] as Array<any>
      } as any
    } as any,
    errorMessages: {} as any,
    selects: {
      cardTypes: [] as Array<SelectComponentInterface>,
      purpose: [] as Array<SelectComponentInterface>,
      packingCategories: [] as Array<SelectComponentInterface>,
      languages: [] as Array<SelectComponentInterface>,
      clientTypes: [] as Array<SelectComponentInterface>,
      releaseTypes: [] as Array<SelectComponentInterface>,
      reasons: [] as Array<SelectComponentInterface>,
      certificates: [] as Array<any>
    } as any,
    lang: store.getters["localization/getCurrent"],
    selectedTab: 1,
    totalSum: 0 as number,
    createBeneficiaryModal: false as boolean,
    isShowDocuments: true as boolean,
    isShowDocumentsNumber: false as boolean,
    isLoading: false,
    breadcrumb: [
      {
        text: "orders.title",
        disabled: false,
        href: "/orders"
      },
      {
        text: "orders.form.header.create",
        disabled: true,
        href: `/orders/edit`
      }
    ] as Array<any>,
    defaultCountry: null as any,
    comments: {} as any,
    oldModel: null as any,
    forceKey: 0 as number,
    contents: {} as any,
    powerOfAttorney: null as any,
    forms: [
      "allocation",
      "order",
      "services",
      "payments",
      "stock",
      "presented_documents",
      "expertise",
      "conclusion",
      "certification_request"
    ],
    formsErrors: {} as any,
    paymentsTotalPrice: 0 as number,
    dialog: false
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        this.model = this.value;

        if (this.model.hasOwnProperty("additions")) {
          if (this.model.additions.hasOwnProperty("notes")) {
            this.selectedTab = 0;
            this.comments = this.parseComments(this.model.additions.notes);
          }
        }

        if (this.model.hasOwnProperty("document_contents")) {
          for (const item of this.model.document_contents) {
            this.contents[item.type] = item;
          }
        }
      }
    },
    "model.documents": {
      immediate: true,
      deep: true,
      handler() {
        this.$forceUpdate();
      }
    }
  },

  computed: {
    expertsPercent() {
      return this.model.experts.reduce(
        (
          total: number,
          { percent, user_id }: { percent: number; user_id: number }
        ) => {
          if (Number(percent) && this.model.order.handler_id !== user_id) {
            return total + Number(percent);
          }
          return total;
        },
        0
      );
    },
    maxDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    headers() {
      return this.stockConfig.headers.map((item: any) => ({
        ...item,
        text: this.$t(item.text)
      }));
    },
    canViewCertificates() {
      return ["admin", "vice_president", "chairman"].includes(
        this.currentRole?.alias
      );
    },
    currentRole() {
      return this.$store.getters["user/currentRole"];
    },
    isAdmin() {
      return this.currentRole.alias === "admin";
    },
    isBookkeeper() {
      return (
        this.currentRole.alias === "bookkeeper" ||
        this.currentRole.alias === "bookkeeper_chief"
      );
    },
    isExpert() {
      return this.currentRole.alias === "expert";
    },
    isSubdivisionHead() {
      return this.currentRole.alias === "subdivision_head";
    },
    statusChanged() {
      return this.currentStatusAlias !== this.model.order.status.alias;
    }
  },

  async beforeMount() {
    await this.loadData();
  },

  methods: {
    openOrder(orderId: number): void {
      window.open(this.$router.resolve(`/orders/preview/${orderId}`).href);
    },
    setPaymentsPrice(price: number) {
      this.paymentsTotalPrice = price;
    },
    async reload(): Promise<void> {
      await this.$forceUpdate();
    },
    getOrderSum(ev: any) {
      this.totalSum = ev;
    },
    async getData() {
      this.$emit("update");
    },
    onTabChange(tabIndex: number): void {
      this.loadingPayments = tabIndex === 3;
    },
    async loadData() {
      this.loading = true;
      try {
        const headers = {
          "x-localization": this.model.order.language
        };

        const response = await Promise.all([
          API.purposeOfUse().getList(this.model.order.service_type_id, headers),
          API.orders().getOrderReleaseModes(),
          API.clients().getClientTypes(),
          API.orders().getCardTypes(this.model.order.service_type_id),
          API.orders().getLanguages(),
          API.orders().getExpCertificates(Number(this.$route.params.id)),
          API.expertiseTypes().getList(),
          API.users().getList({
            filter: {
              service_type: this.model.order.service_type_id,
              subdivision: this.model.order.subdivision_id
            }
          })
        ]);

        await this.setServerResponse(response);
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    validate() {
      for (const key of this.forms) {
        if (this.$refs[key]) {
          this.formsErrors[key] = !(this.$refs[key] as Vue).validate();
        }
      }

      for (const key in this.formsErrors) {
        if (this.formsErrors[key]) {
          return false;
        }
      }
      return true;
    },
    async submit(): Promise<void> {
      this.isLoading = true;
      const form = this.$refs.order as Vue;
      try {
        if (this.validate()) {
          this.model.documents = this.model.documents.filter(
            (item: any) => item.file_type !== "confirm_represent_power"
          );

          if (this.powerOfAttorney.file) {
            this.model.documents.push(this.powerOfAttorney);
          }

          const model = { ...this.model };
          model.order.client_id = model.order.client.id;
          model.experts = model.experts.filter(
            (item: any) => item.user_id !== model.order.handler_id
          );

          model.document_contents = [];

          for (const key in this.contents) {
            model.document_contents.push(this.contents[key]);
          }

          await this.$API.orders().edit(Number(this.$route.params.id), model);

          this.dialog = !this.statusChanged;
          if (!this.dialog) {
            this.$emit("update");
          }
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
        } else {
          // await setTimeout(() => {
          //   const firstErrorInput = form.inputs.find(
          //     (item: any) => item.messagesToDisplay.length
          //   );
          //
          //   if (firstErrorInput) {
          //     this.$vuetify.goTo(firstErrorInput.$el, {
          //       offset: 150
          //     });
          //   }
          // }, 500);
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          if (e.errors.length === 1) {
            if (
              e.errors.hasOwnProperty("order") &&
              e.errors.order.hasOwnProperty("required_guaranty_sum")
            ) {
              this.selectedTab = 1;
            }
          } else if (e.errors.length) {
            this.selectedTab = 0;
          }

          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );

            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);

          setTimeout(() => {
            this.errorMessages = {};
          }, 4000);
        }
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.isLoading = false;
    },
    async setServerResponse(response: Array<any>) {
      const [
        purpose,
        releaseModes,
        clientTypes,
        cardTypes,
        languages,
        certificates,
        expertiseTypes,
        experts
      ] = response;

      this.selects = {
        expertiseTypes,
        experts,
        purpose,
        clientTypes,
        certificates,
        languages: languages.items,
        cardTypes: cardTypes.items,
        releaseTypes: releaseModes.items
      };

      this.setDefaultTab();
      this.$nextTick(() => {
        this.oldModel = JSON.parse(JSON.stringify(this.model));
        this.$forceUpdate();
      });
    },
    setContent(event: string, key: string): void {
      if (!this.contents[key]) {
        this.contents[key] = {};
      }

      this.contents[key].content = event;
      this.contents[key].type = key;
    },
    setDocuments(event: any): void {
      this.model.documents = event;
    },
    setStock(event: Array<any>): void {
      this.model.details.goods = event;
    },
    setDefaultTab() {
      if (
        this.model.order.status &&
        ["processing", "issued", "canceled", "confirmed"].includes(
          this.model.order.status.alias
        )
      ) {
        this.selectedTab = 1;
        return;
      } else {
        this.selectedTab = 3;
      }
    },
    async downloadExpertiseReport(): Promise<void> {
      window.open(
        `${process.env.VUE_APP_API_SERVER_URL}/orders/${this.$route.params.id}/pdf/expert_report`
      );
    },
    setGuaranty(event: boolean): void {
      this.model.details.guaranty_required = event;
    },
    toggleView(): void {
      this.$router.push(`/orders/preview/${this.$route.params.id}`);
    },
    addDocument(): void {
      this.model.documents.push({ file_type: "certification" });
    }
  }
});
