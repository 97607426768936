
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "CreatePayment",

  props: {
    newPaymentDialog: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  data: () => ({
    rules,
    model: {} as any,
    paymentMethods: [] as Array<any>,
    invoices: [] as Array<any>,
    errorMessages: {} as any,
    loading: false as boolean
  }),

  computed: {
    disabledAddPayment(): boolean {
      return (
        this.loading ||
        !this.model.payment_method_id ||
        !this.model.order_invoice_id ||
        !this.model.proof_document
      );
    }
  },

  async mounted(): Promise<void> {
    await this.loadData();
  },

  methods: {
    async loadData(): Promise<void> {
      try {
        this.paymentMethods = await this.$API.orders().getPaymentMethods();
        this.invoices = await this.$API
          .invoices()
          .getOrderInvoices(Number(this.$route.params.id));
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    toggleDialog() {
      this.$emit("toggle");
    },
    async createPayment() {
      this.loading = true;
      try {
        if ((this.$refs.form as Vue).validate()) {
          const model = { ...this.model };
          model.order_id = this.$route.params.id;

          if (!model.comments) {
            delete model.comments;
          }

          await this.$API.payments().create(model);
          await this.$emit("update");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }

        this.$emit("update");
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 3000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    }
  }
});
