
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";
import ContentEditor from "@/components/form-components/ContentEditor.vue";
import FileUploader from "@/components/form-components/FileUploader.vue";

export default Vue.extend({
  components: { ContentEditor, FileUploader },

  name: "ExpertConclusion",

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    documents: {
      type: Array,
      required: true,
      default: () => []
    },
    errorMessages: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  data: () => ({
    rules,
    selects: {
      categories: [] as Array<SelectComponentInterface>
    } as any,
    subcategories: {} as any,
    forceKey: 0 as number,
    toEdit: false as boolean,
    toCancel: false as boolean,
    model: { type: "conclusion" } as any,
    maxDocumentCounter: 20
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        this.model = this.value;
      }
    },
    model() {
      if (!this.model.type) {
        this.model.type = "conclusion";
      }
      this.$emit("input", this.model);
    }
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    async loadData(): Promise<void> {
      try {
        this.selects.categories = await this.$API
          .goodsCategories()
          .getParentsList();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
    },
    forceRender(): void {
      this.forceKey = this.forceKey++;
    },
    deleteRow(index: number): void {
      this.documents.splice(index, 1);
    },
    addRow(): void {
      this.documents.push({ file_type: "conclusion" });
    },
    async submit(): Promise<void> {
      this.$emit("submit");

      if (this.toCancel) {
        await this.$router.push("/orders");
      }
    }
  }
});
